import ReactMapGL, {Marker} from 'react-map-gl'
import CircleSharpIcon from '@mui/icons-material/CircleSharp';
import RoomIcon from '@mui/icons-material/Room';
import React, { Component, useState } from 'react'
import APIClient from '../../../api.service'

export default function CustomMarker(station) {
  const style = {
    padding: '0px',
    margin: '0px',
    color: station.isChild ? 'green' : '#752121',
  };

  const textStyle = {
    position: "absolute",
    lineHeight: 1,
    top: "1.5em",
    padding: '0px',
    margin: '0px',
    color: '#fff',
    fontSize: '2.2em'
  };

  const floatStyle = {
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: 'pointer',
  }
  
  function showModal(stationID) {
    station.handler(true);
  }

  return (
      <Marker key={station.index} longitude={station.station.longitude} latitude={station.station.latitude} anchor="bottom">
          <div style={floatStyle} onClick={() => showModal(station.station.ID)}>
            <div id={station.index} className="mapMarkerStyle" style={style}>
              <RoomIcon style={{fontSize: '3em'}}></RoomIcon>
            </div>
            <div style={textStyle}>
            {station.station.satelliteID} - {station.station.stationID} {station.isChild ? "C" : ""}
            </div>
          </div>
          
      </Marker>
  );
}