import React from 'react';
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import General from './SubMenus/General'
import Satellites from './SubMenus/Satellites'
import Stations from './SubMenus/Stations'
import StationTypes from './SubMenus/StationTypes'
import APIClient from '../../api.service'
import {Spin} from 'antd'
//import '../Screens.css'

export default function Configuration() {
  
  const [currentPage, setCurrentPage] = React.useState('1'); 
  const [config, setConfig] = React.useState(null);
  const [schedules, setSchedules] = React.useState(null);
  const [activeSchedule, setActiveSchedule] = React.useState(null);
  const [roleID, setRoleID] = React.useState(null); 

  const handleChange = (event, newValue) => {
    setCurrentPage(newValue);
  };

  React.useEffect(() => {
    let apiClient = new APIClient();
    apiClient.getConfiguration().then((response) => {
      setConfig(response);
    });
    apiClient.getSavedSchedules().then((response) => {
      setSchedules(response);
      response.map(function (schedule, index) {
        if(parseInt(schedule.active)) {
          setActiveSchedule(schedule);
        }
      });
    });
    apiClient.getUserRole().then((response) => {
      setRoleID(response[0].roleID);
    });
  }, []);

  const handler = (conf, active) => {
    setConfig(conf);
    setActiveSchedule(active); 
  }

  if (schedules && config) {
  return (
    <div className="pageContent">
      <Box sx={{ width: '91vw', height: '40vw', typography: 'body1' }}>
        <TabContext value={currentPage}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label=""> //
              <Tab label="General" value="1" />
              <Tab label="Satellites" value="2" />
              <Tab label="Stations" value="3" />
              <Tab label="Station Types" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <General generalConfig={config.system_properties} 
            activeSchedule={activeSchedule} 
            schedules={schedules} 
            config={config}
            roleID={roleID}
            handler={handler}/>
          </TabPanel>
          <TabPanel value="2">
            <Satellites satellites={config.satellites} stations={config.stations} stationTypes={config.stationTypes}/>
          </TabPanel>
          <TabPanel value="3">
            <Stations stations={config.stations} stationTypes={config.stationTypes}/>
          </TabPanel>
          <TabPanel value="4">
            <StationTypes stationTypes={config.stationTypes} stations={config.stations}/>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  ) } else {
    return (<Spin style={{justifyContent: 'center', width: '100%'}} tip='Loading...'/>);
  } 
}
