import React, { useState } from 'react'
import { Modal, Button, List, Spin, Form, Input, Select } from 'antd'
import { message, Upload } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css'
import APIClient from '../../../api.service'
import './Modals.css'

export default function FirmwareUploadModal() {

  const [show, setShow] = React.useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const props = {
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      //ensure this is a binary file.
      const isBin = file.type === 'application/octet-stream';
      if (isBin && file.name.toLowerCase().includes('firmware')) {
        setFileList([file]);
      } else {
        message.error(`${file.name} is not a binary firmware file`);
      }
      return false;
    },
    fileList,
  };

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      console.log(file);
      formData.append('files[]', file);
    });
    setUploading(true);

    let apiClient = new APIClient();

    apiClient.uploadFirmware(formData).then((response) => {
      console.log(response);
      if(response === true) {
        message.success('Firmware Successfully Uploaded');
      } else {
        message.error('Firmware Failed To Uploaded');
      }
      
      setUploading(false);
    });
  }

  const showModal = () => {
    setShow(true);
  }

  const hideModal = () => {
    setShow(false);
  }

  const handleSubmit = async () => {
  }

  const isFinished = async () => {
    await handleSubmit();
    hideModal();
  }

  const handleCancel = () => {
    hideModal();
  }

  return true ? (
    <div sx={{ width: '30rem', display: 'flex' }}>
      <Button onClick={showModal} >
        Upload New Firmware
      </Button>
      <Modal
        title={'Upload New Firmware'}
        centered
        visible={show}
        width={300}
        closable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            type="primary"
            key='upload'
            onClick={handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{
              marginTop: 16,
            }}
          >
          {uploading ? 'Uploading' : 'Upload'}
          </Button>
        ]}
      >
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </Modal>
    </div>
  ) : (
    <Spin style={{ justifyContent: 'center', width: '100%' }} tip='Loading...' />
  );
}
