import React, { Component } from 'react'
import { Modal, Button, List, Spin, Form, Input, Select } from 'antd'
import 'antd/dist/antd.css'
import APIClient from '../../../api.service'
import StationModal from './StationModal'
import './Modals.css'

export default class LoadScheduleModal extends Component{

  constructor(props) {
    super();

    this.state = {
      show: false,
      scheduleID: props.activeSchedule ? props.activeSchedule.ID : null
    };
    
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  handleSubmit = async () => {
    let apiClient = new APIClient();

    if(this.state.scheduleID == null) {
      this.props.handler('', '', this.props.config, null);
    }

    let scheduleID = this.state.scheduleID;
    let config = this.props.config;
    let newSchedule = null;

    //DISABLE ALL OTHER SCHEDULES 'ACTIVE'
    this.props.schedules.map(function (schedule, index) {
      if(schedule.ID == scheduleID) {
        //Set the state variables
        newSchedule = schedule;
        let newScheduleJSON = JSON.parse(schedule.json);

        //Set the schedule to active
        schedule.active = 1;
        apiClient.updateSavedSchedule(schedule);

        //UPDATE GENERAL CONFIG
        apiClient.setConfiguration(newScheduleJSON.system_properties);

        //UPDATE ALL SATELLITES
        newScheduleJSON.satellites.map(function (satellite, index) {
          apiClient.updateSatellite(satellite);
        });
        //UPDATE ALL STATIONS
        newScheduleJSON.stations.map(function (station, index) {
          apiClient.updateStation(station);
        });

        //UPDATE ALL STATION TYPES
        newScheduleJSON.stationTypes.map(function (stationType, index) {
          apiClient.updateStationType(stationType);
        });
      } else {
        schedule.active = 0;
        apiClient.updateSavedSchedule(schedule);
      }
    });
    if(this.state.scheduleID != null) {
      this.props.handler(newSchedule.name, newSchedule.description, JSON.parse(newSchedule.json), newSchedule);
    }
  }

  isFinished = async () => {
    await this.handleSubmit();
    this.hideModal();
  }

  handleCancel = () => {
    this.setState({scheduleID: this.props.activeSchedule ? this.props.activeSchedule.ID : null});
    this.hideModal();
  }

  setScheduleID = (id) => {
    this.setState({scheduleID: id});
  }
  
  render() {
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 12 },
    };
    return this.props.schedules ? (
        <div sx={{width: '30rem', display: 'flex'}}>
          <Button onClick={this.showModal} >
            Load Schedule
          </Button>
          <Modal 
            title={'Load Saved Schedule File'}
            centered
            visible={this.state.show}
            okButtonProps={{onClick: this.isFinished}}
            onCancel={this.handleCancel}
            width={520}
            okText={'Save'}
            maskClosable = {this.handleCancel}
            closable={false}
          >
            <Form id={'scheduleSelect'} onFinish={this.isFinished} {...formItemLayout}>
              <Form.Item label="Select Schedule To Load:" validateStatus={'success'} hasFeedback>
                <Select value={this.state.scheduleID} onChange={this.setScheduleID}>
                  <Select.Option key={-1} value={null}>CREATE NEW SCHEDULE</Select.Option>
                  {this.props.schedules.map(function (schedule, index) { 
                    return (
                      <Select.Option key={index} value={schedule.ID}>{schedule.name}</Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </Modal>
      </div>
    ) : (
      <Spin style={{justifyContent: 'center', width: '100%'}} tip='Loading...'/>
    );
    
  }
}
