import { BehaviorSubject } from 'rxjs'
import APIClient from '../api.service'

export const AuthenticationProvider = {
  login,
}

async function login(username, password, remember) {
  await initiateLogin(username, password).then( (user) => {
    if(user.access.token && user.refresh.token && remember) {
      sessionStorage.setItem('accessToken', user.access.token);
      sessionStorage.setItem('refreshToken', user.refresh.token);

      localStorage.setItem('refreshToken', user.refresh.token);
      return true;
    } else if(user.access.token && user.refresh.token) {
      sessionStorage.setItem('accessToken', user.access.token);
      sessionStorage.setItem('refreshToken', user.refresh.token);

      return true;
    } else {
      return false;
    }
  });
}

async function initiateLogin(username, password) {
  let apiClient = new APIClient();
  let response = apiClient.login(username, password);
  return await response;
}