import React, { Component } from 'react';
import { Spin, List, Tooltip, Button} from 'antd'
import 'antd/dist/antd.css'
import StationTypeModal from '../Modals/StationTypeModal'
import NewStationTypeModal from '../Modals/NewStationTypeModal'

export default class StationTypes extends Component{

  constructor(props) {
    super();

    this.state = {
      validateStationTypes: 'success'
    };
  }

  handler = () => {
    this.forceUpdate();
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 3 },
    };
    return this.props.stationTypes ? (
      <div>
        <List
          itemLayout="horizontal"
          dataSource={this.props.stationTypes}
          renderItem={stationType => (
            <List.Item>
              <StationTypeModal stationType={stationType} stations={this.props.stations} stationTypes={this.props.stationTypes}/>
            </List.Item>
          )}
        />
        <NewStationTypeModal stationTypes={this.props.stationTypes} handler={this.handler}/>
      </div>
    ) : (
      <Spin style={{justifyContent: 'center', width: '100%'}} tip='Loading...'/>
    );
    
  }
  
}