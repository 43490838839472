import React, { useState } from "react";
// import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/Button";
import "./Login.css";
import {AuthenticationProvider} from '../../Services/AuthenticationProvider'
import { Button, Form, Input, Checkbox, Card, Row, Col } from 'antd'
import 'antd/dist/antd.css'

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  async function onFinish(values) {
    await AuthenticationProvider.login(values.username, values.password, values.remember).then(result => {
      window.location.reload();
    });
  }

  return (
    <div className="Login" style={{display: 'flex', alignItems: 'center', justifyContent: 'center',
     transform: 'translate(-50%, -50%)', position: 'absolute', left: '50%', top: '50%'}}>
      <Card
        sytle={{minHeight: '100vh'}}
      >
        <Form
          name='login'
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{required: true, message: 'Please enter your username'}]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{required: true, message: 'Please enter your password'}]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button size="lg" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}