import React, { Component } from 'react'
import { Modal, Button, List, Spin, Form, Input, Select } from 'antd'
import 'antd/dist/antd.css'
import APIClient from '../../../api.service'
import StationModal from './StationModal'
import './Modals.css'

export default class SatelliteModal extends Component{

  constructor(props) {
    super();

    let filteredStations = [];
    if(props.stations) {
      props.stations.map(function (station, index) {
        if(station.satelliteID == props.satellite.ID) {
          filteredStations.push(station);
        }
      });
    }

    filteredStations.sort((a,b) => (a.stationID > b.stationID) ? 1 : 0);

    this.state = {
      show: false,
      flowRate: props.satellite.flowRate,
      priority: props.satellite.priority,
      scheduleConditionID: props.satellite.scheduleConditionID,
      stations: filteredStations,
      validateFlowRate: 'success',
      validateScheduleConditionID: 'success',
      validatePriority: 'success'
    };
    
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.setMaxFlowRate = this.setMaxFlowRate.bind(this);
    this.setPriority = this.setPriority.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  handleSubmit = () => {

    this.props.satellite.scheduleConditionID = this.state.scheduleConditionID;
    this.props.satellite.priority = this.state.priority;
    this.props.satellite.flowRate = this.state.flowRate;

    let apiClient = new APIClient();
    apiClient.updateSatellite(this.props.satellite).then((response) => {
      this.setState({validateFlowRate: 'success'});
      this.setState({validatePriority: 'success'});
      this.setState({validateScheduleConditionID: 'success'});
      this.hideModal();
    });
  }
  handleCancel = () => {
    this.setState({flowRate: this.props.satellite.flowRate});
    this.setState({scheduleConditionID: this.props.satellite.scheduleConditionID});
    this.setState({priority: this.props.satellite.priority});
    this.setState({validateFlowRate: 'success'});
    this.setState({validatePriority: 'success'});
    this.setState({validateScheduleConditionID: 'success'});
    this.hideModal();
  }

  setMaxFlowRate = (value) => {
    this.setState({flowRate: value.target.value});
    this.setState({validateFlowRate: 'warning'});
  }

  setPriority = (value) => {
    this.setState({priority: value.target.value});
    this.setState({validatePriority: 'warning'});
  }

  setScheduleConditionID = (value) => {
    this.setState({scheduleConditionID: value});
    this.setState({validateScheduleConditionID: 'warning'});
  }
  
  render() {
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
    };
    return this.props.satellite ? (
        <div sx={{width: '30rem', display: 'flex'}}>
          <Button onClick={this.showModal} style={{textAlign: 'left', width: '30rem', height: '40px', marginBottom: '0px', padding: '0', borderStyle: 'none'}}>
                <List.Item.Meta 
                  title={(<b>Satellite {this.props.satellite.ID}</b>)}
                  description={(<p>Priority: {this.props.satellite.priority} │ Flow Rate: {this.props.satellite.flowRate} │ Schedule Condition: <span style={{color: 'green'}}>({this.props.satellite.scheduleConditionID == 1 ? 'On Schedule' : 'Off Schedule'})</span></p>)}
                />
          </Button>
          <Modal 
            title={'Satellite ' + this.props.satellite.ID}
            centered
            visible={this.state.show}
            okButtonProps={{form: 'satellite'+this.props.satellite.ID, key: 'submit', htmlType: 'submit'}}
            onCancel={this.handleCancel}
            width={520}
            okText={'Save'}
            maskClosable = {this.handleCancel}
            closable={false}
          >
            <Form id={'satellite'+this.props.satellite.ID} onFinish={this.handleSubmit} {...formItemLayout}>
              <Form.Item label="Max Flowrate (GPM):" validateStatus={this.state.validateFlowRate} hasFeedback>
                <Input type="text" value={this.state.flowRate} onChange={this.setMaxFlowRate}/>
              </Form.Item>
              <Form.Item label="Priority:" validateStatus={this.state.validatePriority} hasFeedback>
                <Input type="text" value={this.state.priority} onChange={this.setPriority}/>
              </Form.Item>
              <Form.Item label="Schedule State:" validateStatus={this.state.validateScheduleConditionID} hasFeedback>
                <Select value={this.state.scheduleConditionID} onChange={this.setScheduleConditionID}>
                  <Select.Option value='1'>On Schedule</Select.Option>
                  <Select.Option value='0'>Off Schedule</Select.Option>
                </Select>
              </Form.Item>
            </Form>
            <div style={{width: '100%', height: '38em', overflowY: 'scroll', whiteSpace: 'nowrap' }}>
              <List
                itemLayout="horizontal"
                dataSource={this.state.stations}
                renderItem={station => (
                  <List.Item>
                    <StationModal station={station} stationTypes={this.props.stationTypes}/>
                  </List.Item>
                )}
              />
            </div>
          </Modal>
      </div>
    ) : (
      <Spin style={{justifyContent: 'center', width: '100%'}} tip='Loading...'/>
    );
    
  }
}
