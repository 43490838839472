import React, { Component } from 'react'
import { Modal, Button, List, Spin, Form, Input, Select, TimePicker, Switch, Tooltip } from 'antd'
import 'antd/dist/antd.css'
import APIClient from '../../../api.service'
import './Modals.css'
import moment from 'moment'
import {PlusOutlined} from '@ant-design/icons'

export default class StationTypeModal extends Component{

  constructor(props) {
    super();

    this.state = {
      show: false,
      headType: '',
      runTime: 0,
      scheduleConditionID: '0',
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  handleSubmit = () => {
    let apiClient = new APIClient();

    let stationType = {
      headType: this.state.headType,
      runTime: this.state.runTime,
      scheduleConditionID: this.state.scheduleConditionID
    }

    this.props.stationTypes.push(stationType);

    apiClient.createNewStationType(stationType).then((response) => {
      console.log(response);
      this.props.handler();
      this.hideModal();
    });
  }

  handleCancel = () => {
    this.hideModal();
  }

  setHeadType = (value) => {
    this.setState({headType: value.target.value});
  }

  setScheduleConditionID = (value) => {
    this.setState({scheduleConditionID: value});
  }

  setRunTime = (value) => {
    this.setState({ runTime: value.diff(moment().startOf('day'), 'seconds')});
  }

  
  render() {
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
    };
    return true ? (
        <div sx={{width: '30rem', display: 'flex'}}>
          <Tooltip title="Create New Station Type">
            <Button shape="circle" icon={<PlusOutlined />} size='large' onClick={this.showModal}/>
          </Tooltip>
          <Modal 
            title={'Create New Station Type'}
            centered
            visible={this.state.show}
            okButtonProps={{form: 'newStation', key: 'submit', htmlType: 'submit'}}
            onCancel={this.handleCancel}
            width={520}
            okText={'Save'}
            maskClosable = {this.handleCancel}
            closable={false}
          >
            <Form id={'newStation'} onFinish={this.handleSubmit} {...formItemLayout}>

              <Form.Item label="Station Type:">
                <Input type="text" value={this.state.headType} onChange={this.setHeadType}/>
              </Form.Item>
              <Form.Item label="Run Time:">
                <TimePicker showNow={false} format="mm:ss" allowClear={false} onChange={this.setRunTime} value={moment('00:00:00', "mm:ss").add(this.state.runTime, 'seconds')}/>
              </Form.Item>
              <Form.Item label="Schedule State:">
                <Select value={this.state.scheduleConditionID} onChange={this.setScheduleConditionID}>
                  <Select.Option value='1'>On Schedule</Select.Option>
                  <Select.Option value='0'>Off Schedule</Select.Option>
                </Select>
              </Form.Item>
            </Form>
          </Modal>
      </div>
    ) : (
      <Spin style={{justifyContent: 'center', width: '100%'}} tip='Loading...'/>
    );
    
  }
}