import React, { Component } from 'react'
import { Modal, Button, List, Spin, Form, Input, Select, TimePicker, Switch } from 'antd'
import 'antd/dist/antd.css'
import APIClient from '../../../api.service'
import './Modals.css'
import moment from 'moment'
import StationModal from '../Modals/StationModal'

export default class StationTypeModal extends Component{

  constructor(props) {
    super();

    let filteredStations = [];
    if(props.stations) {
      props.stations.map(function (station, index) {
        if(station.stationTypeID == props.stationType.ID) {
          filteredStations.push(station);
        }
      });
    }

    filteredStations.sort((a,b) => (a.stationID > b.stationID) ? 1 : 0);

    this.state = {
      show: false,
      headType: props.stationType.headType,
      runTime: props.stationType.runTime,
      scheduleConditionID: props.stationType.scheduleConditionID,
      validateHeadType: 'success',
      validateRunTime: 'success',
      validateScheduleConditionID: 'success',
      stations: filteredStations
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  handleSubmit = () => {
    let apiClient = new APIClient();
    this.props.stationType.headType = this.state.headType;
    this.props.stationType.scheduleConditionID = this.state.scheduleConditionID;
    this.props.stationType.runTime = this.state.runTime;
    this.setState({validateHeadType: 'success'});
    this.setState({validateRunTime: 'success'});
    this.setState({validateRunTime: 'success'});
    apiClient.updateStationType(this.props.stationType).then((response) => {
      this.hideModal();
    });
  }

  handleCancel = () => {
    this.setState({headType: this.props.stationType.headType});
    this.setState({scheduleConditionID: this.props.stationType.scheduleConditionID});
    this.setState({runTime: this.props.stationType.runTime});
    this.setState({validateHeadType: 'success'});
    this.setState({validateScheduleConditionID: 'success'});
    this.setState({validateRunTime: 'success'});
    this.hideModal();
  }

  setHeadType = (value) => {
    this.setState({validateHeadType: 'warning'});
    this.setState({headType: value.target.value});
  }

  setScheduleConditionID = (value) => {
    this.setState({validateScheduleConditionID: 'warning'});
    this.setState({scheduleConditionID: value});
  }

  setRunTime = (value) => {
    this.setState({validateRunTime: 'warning'});
    this.setState({ runTime: value.diff(moment().startOf('day'), 'seconds')});
  }

  
  render() {
    const formItemLayout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
    };
    return this.props.stationType ? (
        <div sx={{width: '30rem', display: 'flex'}}>
          <Button onClick={this.showModal} style={{textAlign: 'left', width: '28rem', height: '40px', marginBottom: '0px', padding: '0', borderStyle: 'none'}}>
          <List.Item.Meta 
                  title={(<b>{this.props.stationType.headType}</b>)}
                  description={(<p>Run Time: {Math.floor(this.props.stationType.runTime/3600)}h {Math.floor(this.props.stationType.runTime/60)}m {this.props.stationType.runTime%60}s │ Schedule Condition: <span style={{color: 'green'}}>({this.props.stationType.scheduleConditionID == 1 ? 'On Schedule' : 'Off Schedule'})</span></p>)}
                />
          </Button>
          <Modal 
            title={'Station Type:  ' + this.props.stationType.headType}
            centered
            visible={this.state.show}
            okButtonProps={{form: 'station'+this.props.stationType.ID, key: 'submit', htmlType: 'submit'}}
            onCancel={this.handleCancel}
            width={520}
            okText={'Save'}
            maskClosable = {this.handleCancel}
            closable={false}
          >
            <Form id={'station'+this.props.stationType.ID} onFinish={this.handleSubmit} {...formItemLayout}>

              <Form.Item label="Station Type:" validateStatus={this.state.validateHeadType} hasFeedback>
                <Input type="text" value={this.state.headType} onChange={this.setHeadType}/>
              </Form.Item>
              <Form.Item label="Run Time:" validateStatus={this.state.validateRunTime} hasFeedback>
                <TimePicker showNow={false} format="mm:ss" allowClear={false} onChange={this.setRunTime} value={moment('00:00:00', "mm:ss").add(this.state.runTime, 'seconds')}/>
              </Form.Item>
              <Form.Item label="Schedule State:" validateStatus={this.state.validateScheduleConditionID} hasFeedback>
                <Select value={this.state.scheduleConditionID} onChange={this.setScheduleConditionID}>
                  <Select.Option value='1'>On Schedule</Select.Option>
                  <Select.Option value='0'>Off Schedule</Select.Option>
                </Select>
              </Form.Item>
            </Form>
            <div style={{width: '100%', height: '38em', overflowY: 'scroll', whiteSpace: 'nowrap' }}>
              <List
                itemLayout="horizontal"
                dataSource={this.state.stations}
                renderItem={station => (
                  <List.Item>
                    <StationModal station={station} stationTypes={this.props.stationTypes}/>
                  </List.Item>
                )}
              />
            </div>
          </Modal>
      </div>
    ) : (
      <Spin style={{justifyContent: 'center', width: '100%'}} tip='Loading...'/>
    );
    
  }
}