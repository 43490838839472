import React, { useState} from 'react'
import mapboxgl from 'mapbox-gl'
import ReactMapGL, {Marker} from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import APIClient from '../../api.service'
import CircleSharpIcon from '@mui/icons-material/CircleSharp';
import { Spin } from 'antd'
import StationModal from './Modals/StationModal'
import 'mapbox-gl/dist/mapbox-gl.css'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

export default function MapBoxView() {
  const [config, setConfig] = React.useState({});
  const [lng, setLng] = useState(process.env.REACT_APP_LON);
  const [lat, setLat] = useState(process.env.REACT_APP_LAT);
  const [zoom, setZoom] = useState(15.95);

  React.useEffect(() => {
    let apiClient = new APIClient();
    apiClient.getConfiguration().then((response) => {
      setConfig(response);
    });
  }, []);
  
  return config.stations ? (
    <div style={{height: '100%'}}>
      <ReactMapGL 
      reuseMaps={true}
      initialViewState={{
        longitude: lng,
        latitude: lat,
        zoom: zoom
      }}
      style={{width: '100%', height: '100%'}}
      mapStyle="mapbox://styles/mapbox/satellite-v9"
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
    >
        {config.stations.map(function (station, index) {
          return (
            <StationModal key={index} station={station} stationTypes={config.stationTypes}/>
          );
        })}
      </ReactMapGL>
    </div>
  ) : (
    <Spin style={{justifyContent: 'center', width: '100%'}} tip='Loading...'/>
  );
}