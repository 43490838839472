import React, { Component } from 'react';
import { Spin, List} from 'antd'
import 'antd/dist/antd.css'
import APIClient from '../../../api.service'
import SatelliteModal from '../Modals/SatelliteModal';

export default class Satellites extends Component{

  constructor(props) {
    super();

    this.state = {
      validateSatellites: 'success',
    };
    
  }

  render() {
    const formItemLayout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 3 },
    };
    return this.props.satellites ? (
      <div>
        <List
          itemLayout="horizontal"
          dataSource={this.props.satellites}
          renderItem={satellite => (
            <List.Item>
              <SatelliteModal satellite={satellite} stations={this.props.stations} stationTypes={this.props.stationTypes}/>
            </List.Item>
          )}
        />
      </div>
    ) : (
      <Spin style={{justifyContent: 'center', width: '100%'}} tip='Loading...'/>
    );
    
  }
  
}