import './App.css';
import Navigation from "./Components/Navigation/Navigation"
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './Screens/Home/Home.js'
import Map from './Screens/Map/Map.js'
import Configuration from './Screens/Configuration/Configuration.js'
import Login from './Components/Login/Login'
import SystemStatus from './Screens/SystemStatus/SystemStatus'
import ScheduleTimeline from './Screens/Schedule/ScheduleTimeline';
import ScheduleList from './Screens/Schedule/ScheduleList';
import ScheduleRadarSim from './Screens/Schedule/ScheduleRadarSim';

export default function App() {

  if(sessionStorage.getItem('refreshToken') || localStorage.getItem('refreshToken')) {
    return (
      <>
        
        <header style={{padding: 0, margin: 0, minHeight: '3.5em', height: '3%'}}>
          <Navigation /> 
        </header>
        {/* <div style={{display: 'flex'}}> */}
        
        <div style={{width: '100%', height: '95%', maxWidth: '100%', overflowY: 'scroll', whiteSpace: 'nowrap', flexWrap: 'wrap' }}>
          <Router>
            <Routes>
              <Route path='/' exact element={<Home />}/>
              <Route path='/Schedule/Timeline' element={<ScheduleTimeline />}/>
              <Route path='/Schedule/List' element={<ScheduleList />}/>
              <Route path='/Schedule/RadarSimulation' element={<ScheduleRadarSim />}/>
              <Route path='/Map' element={<Map />}/>
              <Route path='/Configuration' element={<Configuration />}/>
              <Route path='/SystemReports' element={<SystemStatus />}/>
            </Routes>
          </Router>
        </div>
        {/* </div> */}
      </>
    );
  } else {
    return (
      <Login/>
    );
  }
  
}
