import axios from 'axios';

const BASE_URI = process.env.REACT_APP_BASE_URL;

const client = axios.create({
  baseURL: BASE_URI,
  json: true,
})

export default class APIClient {
  constructor() {

    if (sessionStorage.getItem('refreshToken')) {
      this.refreshToken = sessionStorage.getItem('refreshToken');
    } else if (localStorage.getItem('refreshToken')) {
      this.refreshToken = localStorage.getItem('refreshToken');
    }
  }

  async login(username, password) {
    let response = this.perform("post", "/auth", { username: username, password: password }, "login");
    return await response;
  }

  ////////////////////////////////////////////////
  ////             Requests                   ////
  ////////////////////////////////////////////////
  getConfiguration() {
    return this.perform("get", "/configuration");
  }
  setConfiguration(data) {
    return this.perform("put", "/configuration", data);
  }

  updateSatellite(satellite) {
    return this.perform("put", '/satellites?ID=' + satellite.ID, satellite);
  }

  updateStation(station) {
    return this.perform("put", '/stations?ID=' + station.ID, station);
  }

  updateStationType(stationType) {
    return this.perform("put", '/stationTypes?ID=' + stationType.ID, stationType);
  }

  createNewStationType(stationType) {
    return this.perform("post", '/stationTypes', stationType);
  }

  getSavedSchedules() {
    return this.perform("get", '/schedules');
  }

  updateSavedSchedule(schedule) {
    return this.perform("put", '/schedules?ID=' + schedule.ID, schedule);
  }

  saveNewSchedule(schedule) {
    return this.perform("put", '/schedules', schedule);
  }

  getCurrentSchedule() {
    return this.perform("get", "/currentSchedule");
  }

  commitSchedule() {
    return this.perform("post", '/commands', { function: 'commit' });
  }

  toggleStationOn(id) {
    return this.perform("post", '/commands', {function: 'toggle_station', station_id: parseInt(id), action: 'start'});
  }

  toggleStationOff(id) {
    return this.perform("post", '/commands', {function: 'toggle_station', station_id: parseInt(id), action: 'stop'});
  }
  reEnableStation(id) {
    return this.perform("post", '/commands', {function: 'toggle_station', station_id: parseInt(id), action: 'enable'});
  }

  getSystemStats() {
    return this.perform("get", '/systemStats');
  }

  getSatelliteCellStatuses() {
    return this.perform("get", '/satellite_cell_status');
  }

  uploadFirmware(firmware) {
    return this.perform("post", '/firmware_upload', firmware);
  }

  getUserRole() {
    return this.perform("get", '/user_role');
  }

  getPumpHouseData() {
    return this.perform("get", '/pump_house_data');
  }

  ////////////////////////////////////////////////
  ////          Perform HTTP Request          ////
  ////////////////////////////////////////////////
  async perform(method, resource, data, type) {
    let headerValues = {};

    switch (type) {
      case "form":
        break;
      case "admin":
        break;
      case "login":
        let auth = "Basic " + btoa(data.username + ":" + data.password);
        headerValues.Authorization = auth;
        break;
      default:
        headerValues = {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
          'Authorization-Refresh': `Bearer ${this.refreshToken}`
        };
        break;
    }

    return client({
      method,
      url: resource,
      data,
      headers: headerValues
    }).then(function (response) { return response.data; })

  }

}