import React from 'react'
import { Divider } from 'antd'
import '../Screens.css'
import APIClient from '../../api.service'
import { Spin, Card, Descriptions, Space, List } from 'antd'
import { CheckCircleOutlined, CheckOutlined, WarningOutlined } from '@ant-design/icons'
import moment from 'moment'
import Chart from './Components/Chart'

export default function SystemStatus() {
  const [cellStats, setCellStats] = React.useState(null);

  const [pumpStats, setPumpStats] = React.useState([]);

  const [overtempStats, setOverTempStats] = React.useState([]);
  const [highPressureStats, setHighPressureStats] = React.useState([]);
  const [lowPressureStats, setLowPressureStats] = React.useState([]);
  const [highFlowStats, setHighFlowStats] = React.useState([]);
  const [phaseLossStats, setPhaseLossStats] = React.useState([]);
  const [powerUpStats, setPowerUpStats] = React.useState([]);
  const [pumpPressureAndFlowData, setPumpPressureAndFlowData] = React.useState([]);

  React.useEffect(() => {
    let apiClient = new APIClient();

    apiClient.getSatelliteCellStatuses().then((response) => {
      setCellStats(response.sort((a, b) => parseInt(a.satelliteID) > parseInt(b.satelliteID) ? 1 : -1));
    });

    apiClient.getPumpHouseData().then((response) => {
      //response = response.sort((a, b) => parseInt(a.ID) < parseInt(b.ID) ? 1 : -1);
      let powStat = [];
      let phaseStat = [];
      let highFlowStat = [];
      let lowPressStat = [];
      let highPressStat = [];
      let ot = [];
      let pstat = [];
      let pumpPressFlow = [];

      /*Just grab the last 24 hours. This will need to be more intuitive in the future
      but for now this will be sufficient to ensure loading doesn't take too long as this
      feature is needed urgently. 
      */
      let datalength = ((response.length >= 8640) ? (response.length - 8640) : (0));

      for (let i = datalength; i < response.length; i++) {
        let item = response[i];
        let reportTime = moment(moment.utc(item.reportTime).local()).format('M/D/YY h:mm a');
        pumpPressFlow.push({ datetime: reportTime, name: i, pressure: parseInt(item.pressureStatus), flow: parseInt(item.flowRate) });

        if (parseInt(item.powerUpStats)) { powStat.push({ time: reportTime }) }
        if (parseInt(item.phaseLossAlarm)) { phaseStat.push({ time: reportTime }) }
        if (parseInt(item.highFlowAlarmStatus)) { highFlowStat.push({ time: reportTime }) }
        if (parseInt(item.lowPressureAlarmStatus)) { lowPressStat.push({ time: reportTime }) }
        if (parseInt(item.highPressureStats)) { highPressStat.push({ time: reportTime }) }

        if (parseInt(item.overtempOneStatus)) { ot.push({ name: 'Main 1', time: reportTime }) }
        if (parseInt(item.overtempTwoStatus)) { ot.push({ name: 'Main 2', time: reportTime }) }

        pstat = [
          { name: 'Jockey', status: parseInt(item.jockeyPumpStatus) },
          { name: 'Main 1', status: parseInt(item.pumpOneStatus) },
          { name: 'Main 2', status: parseInt(item.pumpTwoStatus) }
        ];
      }

      setPowerUpStats(powStat);
      setPhaseLossStats(phaseStat);
      setHighFlowStats(highFlowStat);
      setLowPressureStats(lowPressStat);
      setHighPressureStats(highPressStat);
      setOverTempStats(ot);
      setPumpStats(pstat);
      setPumpPressureAndFlowData(pumpPressFlow);
    });
  }, []);

  return (cellStats && pumpPressureAndFlowData.length > 0) ? (
    <div className="pageContent">
      <div style={{ display: 'flex' }}>
        <Card title="Cellular Status" style={{ width: '25rem', minWidth: '25rem' }}>
          {cellStats.map(function (satellite, index) {
            let status = false;
            moment.utc(satellite.lastCellPoll).isAfter(moment().subtract(10, 'minutes')) ? status = true : status = false;
            return (
              <Card
                key={index}
                style={{ marginTop: '1em', fontSize: '14px', padding: '0' }}
                type="inner"
                headStyle={{ fontSize: '15px', fontWeight: '700' }}
                title={(<> {status ? <CheckCircleOutlined style={{ color: 'green' }} /> : <WarningOutlined style={{ color: 'red' }} />} Satellite: {satellite.satelliteID} </>)}
              >
                <Descriptions title="" style={{}}>
                  <Descriptions.Item span={3} label="Signal Strength">{satellite.cellSignalStrength}</Descriptions.Item>
                  <Descriptions.Item span={3} label="Last Communication">{moment.utc(satellite.lastCellPoll).fromNow()}</Descriptions.Item>
                </Descriptions>
              </Card>
            );
          })}
        </Card>
        <Divider type='vertical' />
        <div style={{ display: "block", width: '75%' }}>
          <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            <Chart name={'System Pressure (PSI)'} data={pumpPressureAndFlowData.map(timeseries => timeseries.pressure)}
              xAxis={pumpPressureAndFlowData.map(timeseries => timeseries.datetime)} />

            <Chart name={'System Flow Rate (GPM)'} data={pumpPressureAndFlowData.map(timeseries => timeseries.flow)}
              xAxis={pumpPressureAndFlowData.map(timeseries => timeseries.datetime)} />
            <List
              size="large"
              header={<div>Current Pump Status</div>}
              bordered
              dataSource={pumpStats}
              renderItem={pump => <List.Item>{pump.status ? <CheckCircleOutlined style={{ color: 'green' }} /> : <WarningOutlined style={{ color: 'orange' }} />} {pump.name}: {pump.status ? 'On' : 'Off'}</List.Item>}
            />

            <Divider orientation="left">Alerts</Divider>
            <div style={{ width: '100%', maxHeight: '30em', overflowY: 'scroll', whiteSpace: 'nowrap' }}>
              <List
                size="large"
                locale={{ emptyText: 'No Alerts' }}
                header={<div>Overtemp Alerts</div>}
                bordered
                dataSource={overtempStats}
                renderItem={alarm => <List.Item>{<WarningOutlined style={{ color: 'red' }} />} {alarm.name} overtemp alarm set off at {alarm.time}</List.Item>}
              />
            </div>
            <div style={{ width: '100%', maxHeight: '30em', overflowY: 'scroll', whiteSpace: 'nowrap' }}>
              <List
                size="large"
                locale={{ emptyText: 'No Alerts' }}
                header={<div>High Pressure Alerts</div>}
                bordered
                dataSource={highPressureStats}
                renderItem={alarm => <List.Item>{<WarningOutlined style={{ color: 'red' }} />} Alarm set off at {alarm.time}</List.Item>}
              />
            </div>
            <div style={{ width: '100%', maxHeight: '30em', overflowY: 'scroll', whiteSpace: 'nowrap' }}>
              <List
                size="large"
                locale={{ emptyText: 'No Alerts' }}
                header={<div>Low Pressure Alerts</div>}
                bordered
                dataSource={lowPressureStats}
                renderItem={alarm => <List.Item>{<WarningOutlined style={{ color: 'red' }} />} Alarm set off at {alarm.time}</List.Item>}
              />
            </div>
            <div style={{ width: '100%', maxHeight: '30em', overflowY: 'scroll', whiteSpace: 'nowrap' }}>
              <List
                size="large"
                locale={{ emptyText: 'No Alerts' }}
                header={<div>High Flow Alerts</div>}
                bordered
                dataSource={highFlowStats}
                renderItem={alarm => <List.Item>{<WarningOutlined style={{ color: 'red' }} />} Alarm set off at {alarm.time}</List.Item>}
              />
            </div>
            <div style={{ width: '100%', maxHeight: '30em', overflowY: 'scroll', whiteSpace: 'nowrap' }}>
              <List
                size="large"
                locale={{ emptyText: 'No Alerts' }}
                header={<div>Phase Loss Alerts</div>}
                bordered
                dataSource={phaseLossStats}
                renderItem={alarm => <List.Item>{<WarningOutlined style={{ color: 'red' }} />} Alarm set off at {alarm.time}</List.Item>}
              />
            </div>
            <div style={{ width: '100%', maxHeight: '30em', overflowY: 'scroll', whiteSpace: 'nowrap' }}>
              <List
                size="large"
                locale={{ emptyText: 'No Alerts' }}
                header={<div>Power Up Alerts</div>}
                bordered
                dataSource={powerUpStats}
                renderItem={alarm => <List.Item>{<WarningOutlined style={{ color: 'red' }} />} Alarm set off at {alarm.time}</List.Item>}
              />
            </div>

          </Space>
        </div>

      </div>
    </div>
  ) : (
    <Spin style={{ justifyContent: 'center', width: '100%' }} tip='Loading...' />
  );
}
