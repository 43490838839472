import React from 'react'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
//import '../Screens.css'
import APIClient from '../../api.service'
import { Chart } from 'react-google-charts'
import {List, Button, Form, Select} from 'antd'
import moment from 'moment'
import ScheduleRadar from './SubMenus/ScheduleRadar'

export default function ScheduleList() {
  const [allData, setAllData] = React.useState([]);

  React.useEffect(() => {
    let apiClient = new APIClient();

    apiClient.getCurrentSchedule().then((schedule) => {
      setAllData(schedule.sort((a,b) => (a.startTime > b.startTime) ? 1 : -1));
    });
  }, []);

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  };

  return (
    <div style={{height: '100%', padding: '2em'}}>
      {allData.length === 0 ? (<div></div>) : (
        <List
          itemLayout="horizontal"
          dataSource={allData}
          renderItem={station => (
            <List.Item>
              <List.Item.Meta 
              title={(<b>Satellite {station.satelliteID} - Station {station.stationID}</b>)}
              description={(<p>Run Time: {moment(moment.utc(station.startTime).local()).format('h:mm a')} - {moment(moment.utc(station.endTime).local()).format('h:mm a')}</p>)}
            />
            </List.Item>
          )}
        />
      )}
    </div>
  );
}