// src/App.js'Mon''
import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
class Chart extends Component {
  constructor(props) {
    super(props);

  }


  render() {
    return (

      <ReactEcharts
        option={{
          title: {
            text: this.props.name,
            subtext: 'Last 24 hours',
            left: 'center'
          },
          xAxis: {
            type: 'category',
            data: this.props.xAxis
          },
          yAxis: {
            type: 'value'
          },
          series: [{ 
            data: this.props.data,
            type: 'line'
          }],
          tooltip: {
            trigger: 'axis',
          },
          lazyUpdate: true,
        }}
      />
    );
  }
}
export default Chart;