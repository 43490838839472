import React from 'react'
import './Navigation.css'
import HomeIcon from '@material-ui/icons/Home'
import EventNoteIcon from '@mui/icons-material/EventNote'
import MapIcon from '@mui/icons-material/Map'
import ConstructionIcon from '@mui/icons-material/Construction'
import SecuritySharpIcon from '@mui/icons-material/SecuritySharp'
import { Button, Menu } from 'antd'
import { useState } from 'react'
import APIClient from '../../api.service'

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem('Home', '/', <HomeIcon />),
  getItem('Schedule', 'Schedule', <EventNoteIcon />, [
    getItem('Timeline', '/Schedule/Timeline'),
    getItem('List', '/Schedule/List'),
    getItem('Radar Simulation', '/Schedule/RadarSimulation'),
  ]),
  getItem('Map', '/Map', <MapIcon />),
  getItem('Configuration', '/Configuration', <ConstructionIcon />),
  getItem('System Reports', '/SystemReports', <SecuritySharpIcon />),
];


const Navigation = () => {
  const [current, setCurrent] = useState('/' + window.location.pathname.split("/").pop());

  const handleClick = (value) => {
    window.location.pathname = value.key;
  }

  const deploySchedule = () => {
    let apiClient = new APIClient();
    apiClient.commitSchedule();
  }

  return (
    <div
      style={{
        height: '100%',
        padding: 0,
        margin: 0,
        display: 'flex'
      }}
    >
      <Menu
        defaultOpenKeys={[current]}
        selectedKeys={[current]}
        mode="horizontal"
        theme="dark"
        onClick={handleClick}
        items={items}
        style={{height: '100%', width: '100%'}}
      >
      </Menu>
      <Menu
        mode="horizontal"
        theme="dark"
        onClick={deploySchedule}
        items={[getItem('Deploy Schedule', 'commit', <SecuritySharpIcon />)]}
        style={{height: '100%'}}
      >
      </Menu>
    </div>
  );
};

export default Navigation;